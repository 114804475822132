import { createContext, useContext } from 'react';
import { ValidationEvent } from '../CustomJSRuleEngineV2/ExecutionService/DPSValidations';
import { MessageClasses } from '../DocumentEditRules';

export const defaultMessageClassesState: MessageClasses = {
    errorMessages: {
        messages: [],
        fieldMessages: {},
    },
    warningMessages: {
        messages: [],
        fieldMessages: {},
    },
};

type DocumentValidator = {
    validateEvent: (event: ValidationEvent) => Promise<boolean>;

    shouldDisableActionButton: (
        eventType: Exclude<ValidationEvent['type'], 'FocusLossEvent' | 'WorkflowLoadEvent'>
    ) => boolean;

    postValidationResults: ((params: { documentId: number }) => Promise<void>) | null;

    hasRanValidations: boolean;

    isValidating: boolean;

    messageClasses: MessageClasses;

    validationExceptions: {
        /**
         * Contains the full list of errors (associated to a field or not)
         */
        errors: string[];
        /**
         * Contains the full list of warnings (associated to a field or not)
         */
        warnings: string[];
    };

    /**
     * This is only relevant in Buyer Portal - Parking Lot
     */
    validationExceptionsDialogOptions: {
        hideValidationWarningsOnButtonActions: boolean;
    };
};

const DocumentValidatorContext = createContext<DocumentValidator>({
    validateEvent: () => Promise.resolve(true),
    shouldDisableActionButton: () => false,
    postValidationResults: null,
    isValidating: false,
    hasRanValidations: false,
    messageClasses: defaultMessageClassesState,
    validationExceptions: {
        errors: [],
        warnings: [],
    },
    validationExceptionsDialogOptions: {
        hideValidationWarningsOnButtonActions: false,
    },
});

export const DocumentValidatorProvider = DocumentValidatorContext.Provider;

export function useDocumentValidatorContext() {
    const state = useContext(DocumentValidatorContext);

    return state;
}

interface DocumentValidatorInjectedProps {
    documentValidatorV2: DocumentValidator;
}

export function withDocumentValidatorV2<P>(
    Component: React.ComponentType<P & DocumentValidatorInjectedProps>
): React.ComponentType<P> {
    return (props: P) => {
        const documentValidatorV2 = useDocumentValidatorContext();

        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                documentValidatorV2={documentValidatorV2}
            />
        );
    };
}
